import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "@/queries/client";
import { components } from "@/lib/openapi";
import { ExploreConfig } from "@/types/explore";

export type Report = components["schemas"]["ReportSchema"];
export type Summary = components["schemas"]["SummarySchema"];

export function getReport({ report = undefined, enabled = true } = {}) {
  if (report) {
    return useQuery({
      enabled,
      queryKey: ["reports", report],
      queryFn: async ({ signal }) => {
        const { data, error } = await client
          .get()
          .GET("/api/report/{report_id}", {
            signal,
            params: {
              path: {
                report_id: report,
              },
            },
          });
        if (data) return data;
        console.warn(error);
        throw new Error();
      },
    });
  } else {
    console.warn("Missing report id for getReport");
  }
}

export function getAllReports() {
  return useQuery({
    queryKey: ["all_reports"],
    queryFn: async ({ signal }) => {
      // @ts-ignore
      const { data, error } = await client.get().GET("/api/report", {
        signal,
      });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}

export function getLatestReport() {
  return useQuery({
    queryKey: ["latest_report"],
    retry: 1,
    queryFn: async ({ signal }) => {
      // @ts-ignore
      const { data, error } = await client.get().GET("/api/report/latest", {
        signal,
      });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}

export function getReportComponentData({
  report,
  component,
}: {
  report: number;
  component: number;
}) {
  return useQuery({
    queryKey: ["reports", report, "components", component],
    queryFn: async ({ signal }) => {
      const { data, error } = await client
        .get()
        .GET("/api/report/{report_id}/data/{component_id}", {
          signal,
          params: {
            path: {
              report_id: report,
              component_id: component,
            },
          },
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}

export function buildReport() {
  return useMutation({
    mutationFn: async () => {
      const { data, error } = await client.get().POST("/api/report", {});
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}

export function getZoneData(
  config: ExploreConfig,
  report: number,
  enabled: boolean = true,
) {
  return useQuery({
    enabled,
    queryKey: ["zone_data", report, config],
    queryFn: async ({ signal }) => {
      const { data, error } = await client
        .get()
        .POST("/api/report/zone_data/{report_id}/", {
          signal,
          params: {
            path: {
              report_id: report.toString(),
            },
          },
          body: {
            config,
          },
        });

      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}

export type DataRequestSchema = components["schemas"]["DataRequestSchema"][];
export type ZoneQuery = components["schemas"]["ZoneQuery"];

export function getAggregateData(
  config: DataRequestSchema,
  report: number,
  enabled: boolean = true,
) {
  return useQuery({
    enabled,
    queryKey: ["aggregate_data", report, config],
    queryFn: async ({ signal }) => {
      const { data, error } = await client
        .get()
        .POST("/api/report/{report_id}/aggregate", {
          signal,
          params: {
            path: {
              report_id: report,
            },
          },
          body: config,
        });

      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}

export type WaitTimeRequestSchema =
  components["schemas"]["WaitTimeRequestSchema"];

export function getWaitTimeData(
  config: WaitTimeRequestSchema,
  report: number,
  enabled: boolean = true,
) {
  return useQuery({
    enabled,
    queryKey: ["wait_times", report, config],
    queryFn: async ({ signal }) => {
      const { data, error } = await client
        .get()
        .POST("/api/report/{report_id}/wait", {
          signal,
          params: {
            path: {
              report_id: report,
            },
          },
          body: config,
        });

      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}
