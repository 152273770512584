import React from "react";
import RetailSummary from "@/pages/retail/RetailSummary";
import StoreList from "@/pages/retail/StoreList";
import { useSessionData } from "@/stores/SessionDataContext";
import { useParams } from "react-router-dom";
import { useReportContext } from "@/stores/ReportContext";
import { getRetailReport, prefetchRetailReports } from "@/queries/retail";
import { useQuery, useQueryClient } from "@tanstack/react-query";

function LoadingState() {
  return (
    <div className="w-full h-full flex items-center justify-center py-12">
      <div className="flex flex-col items-center space-y-4">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-500"></div>
        <div className="text-slate-400">
          Loading data. Please wait a moment...
        </div>
      </div>
    </div>
  );
}

function RetailHome() {
  const { restrict_store, stores } = useSessionData();
  const { reportId } = useReportContext();
  const queryClient = useQueryClient();
  const singleStore = !!restrict_store;
  const { store } = useParams();
  const [hasPrefetched, setHasPrefetched] = React.useState(false);

  const storeId = store ? parseInt(store) : null;
  const currentStores = singleStore ? [] : storeId ? [storeId] : [];

  // Query for current view
  const { isLoading, error, data } = getRetailReport.useQuery({
    report: Number(reportId),
    stores: currentStores,
  });

  // Prefetch other stores after initial load
  React.useEffect(() => {
    if (!singleStore && data && !hasPrefetched) {
      // Only prefetch stores we haven't loaded yet
      const storesToPrefetch = stores
        .map((s) => s.id)
        .filter((id) => !currentStores.includes(id));

      prefetchRetailReports(queryClient, Number(reportId), storesToPrefetch);
      setHasPrefetched(true);
    }
  }, [
    reportId,
    stores,
    singleStore,
    queryClient,
    data,
    currentStores,
    hasPrefetched,
  ]);

  // Only show loading state for initial load
  if (isLoading && !hasPrefetched) {
    return <LoadingState />;
  }

  if (error) {
    return (
      <div className="text-red-500 p-8">
        Error loading data: {error.message}
      </div>
    );
  }

  return (
    <div>
      {!singleStore && <StoreList />}
      <div className={"w-full h-full px-8 py-4"}>
        <RetailSummary stores={currentStores} />
      </div>
    </div>
  );
}

export default RetailHome;
