import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import { AdHocPlayButton } from "@/components/playlist/AdHocPlayButton";
import _ from "lodash";

interface PlayListFilterProps {
  locationTypes: string[];
  categories: string[];
  filterType: string;
  filterCategory: string;
  sortBy: string;
  showCompleted: boolean;
  showIgnored: boolean;
  showUpcomingOnly: boolean;
  onFilterTypeChange: (value: string) => void;
  onFilterCategoryChange: (value: string) => void;
  onSortByChange: (value: string) => void;
  onShowCompletedChange: (checked: boolean) => void;
  onShowIgnoredChange: (checked: boolean) => void;
  onShowUpcomingOnlyChange: (checked: boolean) => void;
}

export function PlayListFilter({
  locationTypes,
  categories,
  filterType,
  filterCategory,
  sortBy,
  showCompleted,
  showIgnored,
  showUpcomingOnly,
  onFilterTypeChange,
  onFilterCategoryChange,
  onSortByChange,
  onShowCompletedChange,
  onShowIgnoredChange,
  onShowUpcomingOnlyChange,
}: PlayListFilterProps) {
  return (
    <>
      <div className="mb-4 flex gap-2 items-center">
        <div className="flex-grow flex flex-wrap gap-4 items-center">
          <div className="flex items-center gap-2">
            <label className="text-sm font-medium">Location:</label>
            <Select value={filterType} onValueChange={onFilterTypeChange}>
              <SelectTrigger className="w-[140px] bg-slate-700 text-white border-slate-600">
                <SelectValue placeholder="Filter by type" />
              </SelectTrigger>
              <SelectContent className="bg-slate-700 text-white border-slate-600">
                <SelectItem
                  value="all"
                  className="text-white hover:bg-slate-600"
                >
                  All
                </SelectItem>
                {locationTypes.map((type) => (
                  <SelectItem
                    key={type}
                    value={type}
                    className="text-white hover:bg-slate-600"
                  >
                    {type
                      .replace(/_/g, " ")
                      .split(" ")
                      .map(
                        (word) =>
                          word.charAt(0).toUpperCase() +
                          word.slice(1).toLowerCase(),
                      )
                      .join(" ")}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex items-center gap-2">
            <label className="text-sm font-medium">Category:</label>
            <Select
              value={filterCategory}
              onValueChange={onFilterCategoryChange}
            >
              <SelectTrigger className="w-[140px] bg-slate-700 text-white border-slate-600">
                <SelectValue placeholder="Filter by category" />
              </SelectTrigger>
              <SelectContent className="bg-slate-700 text-white border-slate-600">
                <SelectItem
                  value="all"
                  className="text-white hover:bg-slate-600"
                >
                  All Categories
                </SelectItem>
                {categories.map((category) => (
                  <SelectItem
                    key={category}
                    value={category}
                    className="text-white hover:bg-slate-600"
                  >
                    {category}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex items-center gap-2">
            <label className="text-sm font-medium">Sort by:</label>
            <Select value={sortBy} onValueChange={onSortByChange}>
              <SelectTrigger className="w-[140px] bg-slate-700 text-white border-slate-600">
                <SelectValue placeholder="Sort by" />
              </SelectTrigger>
              <SelectContent className="bg-slate-700 text-white border-slate-600">
                <SelectItem
                  value="time"
                  className="text-white hover:bg-slate-600"
                >
                  Time
                </SelectItem>
                <SelectItem
                  value="location_type"
                  className="text-white hover:bg-slate-600"
                >
                  Location Type
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div>
          <AdHocPlayButton />
        </div>
      </div>
      <div className="mb-4 flex items-center space-x-4">
        <div className="flex items-center space-x-2">
          <Checkbox
            id="show-completed"
            checked={showCompleted}
            onCheckedChange={(checked) =>
              onShowCompletedChange(checked as boolean)
            }
            className="border-slate-600 text-blue-500"
          />
          <label
            htmlFor="show-completed"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Show Completed Plays
          </label>
        </div>
        <div className="flex items-center space-x-2">
          <Checkbox
            id="show-ignored"
            checked={showIgnored}
            onCheckedChange={(checked) =>
              onShowIgnoredChange(checked as boolean)
            }
            className="border-slate-600 text-blue-500"
          />
          <label
            htmlFor="show-ignored"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Show Ignored
          </label>
        </div>
        <div className="flex items-center space-x-2">
          <Checkbox
            id="show-upcoming"
            checked={showUpcomingOnly}
            onCheckedChange={(checked) =>
              onShowUpcomingOnlyChange(checked as boolean)
            }
            className="border-slate-600 text-blue-500"
          />
          <label
            htmlFor="show-upcoming"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Only Show Upcoming
          </label>
        </div>
      </div>
    </>
  );
}
