import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
} from "react";
import { useSessionData } from "./SessionDataContext";

interface AirportContextType {
  hasConcourse: boolean;
  hasCustoms: boolean;
  hasBaggage: boolean;
}

const AirportContext = createContext<AirportContextType | null>(null);

export const AirportProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const sessionData = useSessionData();

  // Derive airport configuration from session data
  const airportConfig = {
    hasConcourse: sessionData?.airport_config.has_concourse ?? false,
    // Check if customs exists in the airport setup
    hasCustoms: (sessionData?.airport.customs?.length ?? 0) > 0,
    // Check if baggage halls exist in the airport setup
    hasBaggage: (sessionData?.airport.baggage_halls?.length ?? 0) > 0,
  };

  return (
    <AirportContext.Provider value={airportConfig}>
      {children}
    </AirportContext.Provider>
  );
};

export const useAirport = () => {
  const context = useContext(AirportContext);
  if (!context) {
    throw new Error("useAirport must be used within an AirportProvider");
  }
  return context;
};
