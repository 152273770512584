import { AdHocPlayButton } from "@/components/playlist/AdHocPlayButton";
import { PlayDetail } from "@/components/playlist/PlayDetail";
import { PlaylistReport } from "@/components/playlist/PlaylistReport";
import { Checkbox } from "@/components/ui/checkbox";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Play } from "@/queries/plays";
import _ from "lodash";
import { useMemo, useState } from "react";
import { PlayItem } from "@/components/playlist/PlayListItem";
import { PlayListFilter } from "@/components/playlist/PlayListFilter";

interface PlayListDisplayProps {
  plays: Play[];
}

export function PlayListDisplay({ plays }: PlayListDisplayProps) {
  const [selectedPlay, setSelectedPlay] = useState<Play | null>(null);
  const [showCompleted, setShowCompleted] = useState(true);
  const [sortBy, setSortBy] = useState("time");
  const [filterType, setFilterType] = useState("all");
  const [filterCategory, setFilterCategory] = useState("all");
  const [showIgnored, setShowIgnored] = useState(true);
  const [showUpcomingOnly, setShowUpcomingOnly] = useState(false);

  const locationTypes = useMemo(
    () => _.uniq(plays.map((play) => play.location_type)),
    [plays],
  );
  const categories = useMemo(
    () => _.uniq(plays.map((play) => play.category)),
    [plays],
  );

  // Get current interval (time of day in 10-min intervals)
  const currentInterval = useMemo(() => {
    const now = new Date();
    return Math.floor((now.getHours() * 60 + now.getMinutes()) / 10);
  }, []);

  const filteredPlays = plays
    .filter((play) => showCompleted || !play.completed_at)
    .filter((play) => filterType === "all" || play.location_type === filterType)
    .filter(
      (play) => filterCategory === "all" || play.category === filterCategory,
    )
    .filter(
      (play) => !showUpcomingOnly || play.start_interval >= currentInterval,
    )
    .sort((a, b) => {
      if (sortBy === "time") {
        return a.start_interval - b.start_interval;
      } else if (sortBy === "location_type") {
        return a.location_type.localeCompare(b.location_type);
      }
      return 0;
    });

  return (
    <div className="w-full mx-auto">
      <PlayListFilter
        locationTypes={locationTypes}
        categories={categories}
        filterType={filterType}
        filterCategory={filterCategory}
        sortBy={sortBy}
        showCompleted={showCompleted}
        showIgnored={showIgnored}
        showUpcomingOnly={showUpcomingOnly}
        onFilterTypeChange={setFilterType}
        onFilterCategoryChange={setFilterCategory}
        onSortByChange={setSortBy}
        onShowCompletedChange={setShowCompleted}
        onShowIgnoredChange={setShowIgnored}
        onShowUpcomingOnlyChange={setShowUpcomingOnly}
      />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <ScrollArea className="h-[calc(100vh-200px)] rounded-md border border-slate-700 p-4">
          {filteredPlays.map((play) => (
            <PlayItem
              key={play.id}
              play={play}
              isSelected={selectedPlay?.id === play.id}
              onClick={() => setSelectedPlay(play)}
            />
          ))}
        </ScrollArea>
        <div className="bg-slate-900 rounded-lg shadow p-4">
          {selectedPlay && (
            <div className="">
              <PlayDetail
                playId={selectedPlay.id}
                onClose={() => setSelectedPlay(null)}
              />
            </div>
          )}
          {!selectedPlay && (
            <div className="">
              <ScrollArea className="h-[calc(100vh-250px)]">
                <PlaylistReport plays={filteredPlays}></PlaylistReport>
              </ScrollArea>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
