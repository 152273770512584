import { usePage } from "@/components/page/PageContext";
import React, { useEffect } from "react";
import { useReportContext } from "@/stores/ReportContext";
import {
  getAggregateData,
  getWaitTimeData,
  WaitTimeRequestSchema,
  ZoneQuery,
} from "@/queries/report";
import {
  Card,
  ExploreDisplay,
  MultiSeriesChart,
  SingleSeriesChart,
  WaitTimeChart,
} from "@/pages/data/DataChart";
import { useSessionData } from "@/stores/SessionDataContext";
import { getPlays } from "@/queries/plays";
import { PlayListDisplay } from "@/components/playlist/PlayListDisplay";
import { Skeleton } from "@/components/ui/skeleton";

export function TrafficChart({
  query,
  title,
}: {
  query: ZoneQuery;
  title: string;
}) {
  const { reportId } = useReportContext();

  const { data: trafficData, isSuccess: trafficSuccess } = getAggregateData(
    [
      {
        query,
      },
    ],
    reportId,
    true,
  );

  if (!trafficSuccess) {
    return null;
  }

  return (
    <Card title={title}>
      <SingleSeriesChart
        type={"column"}
        values={trafficData[0].values}
        labels={trafficData[0].labels}
        xAxisTitle={"Time"}
        yAxisTitle={"Passengers"}
      />
    </Card>
  );
}

function MultiCustomsPassengers() {
  const { reportId } = useReportContext();
  const { airport } = useSessionData();
  let customNames = [];
  const { data, isLoading, isSuccess } = getAggregateData(
    airport.customs.map((t) => {
      customNames.push(t.name);
      return {
        query: {
          measure: "pax",
          customs_id: t.id,
          type: "ingress_customs",
        },
      };
    }),
    reportId,
  );

  if (!isSuccess || data.length === 0) {
    return null;
  }
  return (
    <Card title="Customs Hall - Passenger Volume">
      <MultiSeriesChart
        series={data.map((d, i) => ({
          name: "Customs " + customNames[i],
          values: d.values,
        }))}
        labels={data[0].labels}
        xAxisTitle={"Time"}
        yAxisTitle={"Passengers"}
        totalRequired={true}
        chartType="column"
      />
    </Card>
  );
}

function MultiBaggagePassengers() {
  const { reportId } = useReportContext();
  const { airport } = useSessionData();
  let baggageNames = [];
  const { data, isLoading, isSuccess } = getAggregateData(
    airport.baggage_halls.map((t) => {
      baggageNames.push(t.name);
      return {
        query: {
          measure: "pax",
          baggage_id: t.id,
          type: "baggage",
        },
      };
    }),
    reportId,
  );

  if (!isSuccess || data.length === 0) {
    return null;
  }
  return (
    <Card title="Intl Bag Claim Hall - Passenger Volume">
      <MultiSeriesChart
        series={data.map((d, i) => ({
          name: "Baggage " + baggageNames[i],
          values: d.values,
        }))}
        labels={data[0].labels}
        xAxisTitle={"Time"}
        yAxisTitle={"Passengers"}
        totalRequired={true}
        chartType="column"
      />
    </Card>
  );
}

export function CustomsPage() {
  const { setTitles } = usePage();
  const { airport } = useSessionData();
  const multiCustoms = airport.customs.length > 1;
  const multiBaggage = airport.baggage_halls.length > 1;
  const { reportId } = useReportContext();
  const { data: plays, isLoading: playsLoading } = getPlays(reportId, {
    locations: ["customs", "intl_baggage"],
  });

  useEffect(() => {
    setTitles(["Customs Overview"]);
  }, []);

  return (
    <div className="w-full grid grid-cols-4">
      <div className="col-span-4">
        <Card title="Customs - Passenger Density">
          <ExploreDisplay
            query={{
              type: "ingress_customs",
              measure: "pax",
            }}
          />
        </Card>
      </div>
      <div className="h-90 col-span-4 p-4">
        {multiCustoms ? (
          <MultiCustomsPassengers />
        ) : (
          <TrafficChart
            query={{
              type: "ingress_customs",
              measure: "pax",
            }}
            title={"Customs Hall - Passenger Volume"}
          />
        )}
      </div>
      <div className="h-90 col-span-4 p-4">
        {multiBaggage ? (
          <MultiBaggagePassengers />
        ) : (
          <TrafficChart
            query={{
              type: "baggage",
              measure: "pax",
            }}
            title={"Intl Bag Claim Hall - Passenger Volume"}
          />
        )}
      </div>
      <div className="col-span-4 p-4">
        <Card
          title="Customs and Intl Baggage Hall Pain Points"
          innerClassName=" w-full"
        >
          {playsLoading && <Skeleton className="w-full h-full" />}
          {plays && <PlayListDisplay plays={plays.plays} />}
        </Card>
      </div>
    </div>
  );
}

export function BaggageHallPage() {
  const { setTitles } = usePage();
  const { airport } = useSessionData();
  const multiBaggage = airport.baggage_halls.length > 1;

  useEffect(() => {
    setTitles(["Intl Baggage Hall"]);
  }, []);
  return (
    <div className="grid grid-cols-4 w-full">
      <div className="col-span-2 h-90">
        {multiBaggage ? (
          <MultiBaggagePassengers />
        ) : (
          <TrafficChart
            query={{
              type: "baggage",
              measure: "pax",
            }}
            title={"Baggage Hall Traffic"}
          />
        )}
      </div>
      {/*<div className="h-90 col-span-2">*/}
      {/*  <WaitTimeOverview query={{customs_id: customId}} />*/}
      {/*</div>*/}
    </div>
  );
}
