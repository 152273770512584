import { useSessionData } from "@/stores/SessionDataContext";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import React, { Fragment, useState } from "react";
import { buildReport } from "@/queries/report";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { switchSession } from "@/queries/user";
import { useReportContext } from "@/stores/ReportContext";

export function SessionSwitcher() {
  const { access, code_iata } = useSessionData();
  const { mutateAsync: getNewSession } = switchSession();

  const requestNewSession = async (id: number) => {
    const { success, token } = await getNewSession(id);
    if (success) {
      document.cookie = `auth_token=${token}; path=/`;

      // Redirect to "/" and force a full page reload
      window.location.href = "/";
    }
  };

  if (access.length < 2) {
    return null;
  }

  return (
    <Menu as="div" className="relative ml-5">
      <div>
        <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 items-center">
          Airport: {code_iata}{" "}
          <ChevronDownIcon className={"ml-2"}></ChevronDownIcon>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-0 focus:outline-none">
          {access.map((item) => (
            <Menu.Item key={item.id}>
              <a
                onClick={() => requestNewSession(item.id)}
                className={
                  "block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-200"
                }
              >
                {item.name}
              </a>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export function ReportDetails() {
  const { report } = useReportContext();

  return (
    <div className="text-sm text-green-700 opacity-75 mr-2 hidden lg:block">
      Last Updated: {new Date(report.ready_at).toLocaleString()}
    </div>
  );
}

export function ReportRefresh() {
  const [refreshing, setRefreshing] = useState(false);
  const { mutateAsync: runBuildReport } = buildReport();

  const { toast } = useToast();

  // Implementing the refresh click behaviour
  const onRefreshClick = async () => {
    setRefreshing(true);

    // Run the build report and start polling
    await runBuildReport();

    toast({
      title: "Refreshing Forecast",
      description: "Please wait 2-3 minutes to prepare a new forecast.",
    });
  };

  return (
    <div className={"items-center hidden lg:flex"}>
      <ReportDetails />
      <Button variant="outline" type={"button"} onClick={onRefreshClick}>
        Refresh Forecast
      </Button>
    </div>
  );
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function ProfileMenu() {
  const navigate = useNavigate();
  const { name, email } = useSessionData();
  const handleLogout = () => {
    const cookies = Cookies.get();
    for (const cookie in cookies) {
      Cookies.remove(cookie);
    }

    navigate("/login");
  };

  return (
    <Menu as="div" className="relative ml-5">
      <div>
        <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 items-center">
          {name.length > 2 ? name : email}{" "}
          <ChevronDownIcon className={"ml-2"}></ChevronDownIcon>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <a
                onClick={handleLogout}
                className={classNames(
                  active ? "bg-gray-100" : "",
                  "block px-4 py-2 text-sm text-gray-700",
                )}
              >
                Sign out
              </a>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
