import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogHeader,
  DialogTitle,
  DialogClose,
} from "@/components/ui/dialog";
import { Trash, Plus, X } from "lucide-react";
import { useReportContext } from "@/stores/ReportContext";

import { useCreateAdHocPlay } from "@/queries/plays";
import { useSessionData } from "@/stores/SessionDataContext";

const playSchema = z
  .object({
    title: z.string().min(1, "Title is required"),
    category: z.string().min(1, "Category is required"),
    location_type: z.enum(
      [
        "terminal",
        "gate",
        "ticketing",
        "checkpoint",
        "restroom",
        "customs",
        "intl_baggage",
        "door",
        "other",
      ],
      {
        required_error: "Please select a location type",
      },
    ),
    location_details: z.string().nullable(),
    terminal: z.string().nullable(),
    concourse: z.string().nullable(),
    start_interval: z.number().min(0).max(144, "Invalid time interval"),
    end_interval: z.number().min(0).max(144, "Invalid time interval"),
    actions: z
      .array(z.string().min(1, "Action cannot be empty"))
      .min(1, "At least one action is required"),
  })
  .refine((data) => data.end_interval >= data.start_interval, {
    message: "End time must be after start time",
    path: ["end_interval"],
  });

type PlayCreateSchema = z.infer<typeof playSchema>;

function AdHocPlayForm({ onClose }: { onClose: () => void }) {
  const { reportId } = useReportContext();
  const { mutate: createPlay } = useCreateAdHocPlay(reportId);
  const [showTerminal, setShowTerminal] = useState(false);
  const [showConcourse, setShowConcourse] = useState(false);
  const { airport_config } = useSessionData();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<PlayCreateSchema>({
    resolver: zodResolver(playSchema),
    defaultValues: {
      title: "",
      category: "",
      location_type: "gate",
      location_details: "",
      terminal: null,
      concourse: null,
      start_interval: 0,
      end_interval: 0,
      actions: [""],
    },
    mode: "onBlur",
  });

  const onSubmit = (data: PlayCreateSchema) => {
    // @ts-ignore
    createPlay(data);
    onClose();
  };

  const generateTimeOptions = () => {
    const options = [];
    for (let i = 0; i <= 144; i++) {
      const hours = Math.floor((i * 10) / 60);
      const minutes = (i * 10) % 60;
      const time = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
      options.push({ value: i, label: time });
    }
    return options;
  };

  const timeOptions = generateTimeOptions();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <div className="space-y-2">
        <Label htmlFor="title" className="text-slate-200">
          Title
        </Label>
        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              className="bg-slate-800 text-slate-100 border-slate-700"
            />
          )}
        />
        {errors.title && (
          <p className="text-red-400 text-sm">{errors.title.message}</p>
        )}
      </div>

      <div className="space-y-2">
        <Label htmlFor="category" className="text-slate-200">
          Category
        </Label>
        <Controller
          name="category"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              className="bg-slate-800 text-slate-100 border-slate-700"
            />
          )}
        />
        {errors.category && (
          <p className="text-red-400 text-sm">{errors.category.message}</p>
        )}
      </div>

      <div className="space-y-2">
        <Label htmlFor="location_type" className="text-slate-200">
          Location Type
        </Label>
        <Controller
          name="location_type"
          control={control}
          render={({ field }) => (
            <select
              {...field}
              className="w-full rounded-md bg-slate-800 text-slate-100 border-slate-700 p-2"
            >
              <option value="">Select location type</option>
              <option value="terminal">Terminal</option>
              <option value="gate">Gate</option>
              <option value="ticketing">Ticketing</option>
              <option value="checkpoint">Checkpoint</option>
              <option value="restroom">Restroom</option>
              <option value="customs">Customs</option>
              <option value="intl_baggage">International Baggage</option>
              <option value="door">Door</option>
              <option value="other">Other</option>
            </select>
          )}
        />
        {errors.location_type && (
          <p className="text-red-400 text-sm">{errors.location_type.message}</p>
        )}
      </div>

      <div className="space-y-2">
        <Label htmlFor="location_details" className="text-slate-200">
          Location Name
        </Label>
        <Controller
          name="location_details"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              className="bg-slate-800 text-slate-100 border-slate-700"
            />
          )}
        />
        <p className="text-sm text-slate-600">
          Hint: Use the exact ID of the location. For ex -{" "}
          <span className="bg-slate-800 p-1 font-mono text-slate-300">A33</span>{" "}
          for Gate A33
        </p>
      </div>

      {!showTerminal && (
        <Button
          type="button"
          variant="link"
          className="p-0 text-slate-300 hover:text-slate-100"
          onClick={() => setShowTerminal(true)}
        >
          [+] Associate with Terminal
        </Button>
      )}
      {showTerminal && (
        <div className="space-y-2">
          <Label htmlFor="terminal" className="text-slate-200">
            Terminal
          </Label>
          <Controller
            name="terminal"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                className="bg-slate-800 text-slate-100 border-slate-700"
              />
            )}
          />
          <p className="text-sm text-slate-600">
            Hint: Use only the exact ID of the terminal. For ex -{" "}
            <span className="bg-slate-800 p-1 font-mono text-slate-300">A</span>{" "}
            for Terminal A
          </p>
        </div>
      )}
      {airport_config.has_concourse && (
        <div>
          {!showConcourse && (
            <Button
              type="button"
              variant="link"
              className="p-0 text-slate-300 hover:text-slate-100"
              onClick={() => setShowConcourse(true)}
            >
              [+] Associate with Concourse
            </Button>
          )}
          {showConcourse && (
            <div className="space-y-2">
              <Label htmlFor="concourse" className="text-slate-200">
                Concourse
              </Label>
              <Controller
                name="concourse"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    className="bg-slate-800 text-slate-100 border-slate-700"
                  />
                )}
              />
            </div>
          )}
        </div>
      )}

      <div className="space-y-2">
        <Label htmlFor="start_interval" className="text-slate-200">
          Start Time
        </Label>
        <Controller
          name="start_interval"
          control={control}
          render={({ field }) => (
            <select
              {...field}
              className="w-full rounded-md bg-slate-800 text-slate-100 border-slate-700 p-2"
              onChange={(e) => field.onChange(Number(e.target.value))}
            >
              {timeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          )}
        />
        {errors.start_interval && (
          <p className="text-red-400 text-sm">
            {errors.start_interval.message}
          </p>
        )}
      </div>

      <div className="space-y-2">
        <Label htmlFor="end_interval" className="text-slate-200">
          End Time
        </Label>
        <Controller
          name="end_interval"
          control={control}
          render={({ field }) => (
            <select
              {...field}
              className="w-full rounded-md bg-slate-800 text-slate-100 border-slate-700 p-2"
              onChange={(e) => field.onChange(Number(e.target.value))}
            >
              {timeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          )}
        />
        {errors.end_interval && (
          <p className="text-red-400 text-sm">{errors.end_interval.message}</p>
        )}
      </div>

      <div className="space-y-2">
        <Label className="text-slate-200">Execute</Label>
        <Controller
          name="actions"
          control={control}
          render={({ field }) => (
            <div className="space-y-2">
              {field.value.map((action, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <Input
                    value={action}
                    onChange={(e) => {
                      const newActions = [...field.value];
                      newActions[index] = e.target.value;
                      field.onChange(newActions);
                    }}
                    placeholder={`Enter execute ${index + 1}`}
                    className="bg-slate-800 text-slate-100 border-slate-700"
                  />
                  {field.value.length > 1 && (
                    <Button
                      type="button"
                      variant="ghost"
                      size="icon"
                      onClick={() => {
                        const newActions = field.value.filter(
                          (_, i) => i !== index,
                        );
                        field.onChange(newActions);
                      }}
                    >
                      <Trash className="h-4 w-4" />
                    </Button>
                  )}
                </div>
              ))}
              <Button
                type="button"
                variant="secondary"
                size="sm"
                onClick={() => field.onChange([...field.value, ""])}
              >
                <Plus className="h-4 w-4 mr-2" /> Add Execute
              </Button>
            </div>
          )}
        />
        {errors.actions && (
          <p className="text-red-400 text-sm">{errors.actions.message}</p>
        )}
      </div>

      <Button type="submit" className="w-full bg-slate-700 hover:bg-slate-600">
        Submit
      </Button>
    </form>
  );
}

export function AdHocPlayButton() {
  // not implemented now!
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">One Time Play</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] bg-slate-900 border-slate-700">
        <DialogHeader>
          <DialogTitle className="text-slate-100">
            Create One-Time Play
          </DialogTitle>
          <DialogClose className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"></DialogClose>
        </DialogHeader>
        <AdHocPlayForm onClose={() => setOpen(false)} />
      </DialogContent>
    </Dialog>
  );
}
