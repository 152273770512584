import React, { FunctionComponent, useMemo, useState } from "react";
import _ from "lodash";
import { Concern, useExplore } from "@/components/explore/ExploreContext";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { PlayDetail } from "@/components/playlist/PlayDetail";
import { ScrollArea } from "@/components/ui/scroll-area";

// Add this component for displaying concerns
function ConcernDisplay({ concern }: { concern: Concern }) {
  return <PlayDetail playId={concern.play_id} />;
}

export const TableView: FunctionComponent = () => {
  const { zones: rawZones, times, concerns } = useExplore();
  const [open, setOpen] = useState(false);
  const [selectedConcern, setSelectedConcern] = useState<Concern | null>(null);

  const zones = _.clone(rawZones).reverse();

  const { min, max } = useMemo(() => {
    let min = Infinity;
    let max = -Infinity;

    zones.forEach((zone) => {
      zone.values.forEach((value) => {
        min = Math.min(min, value);
        max = Math.max(max, value);
      });
    });

    return { min, max };
  }, [zones]);

  // Create concern lookup similar to Heatmap
  const conLookup = useMemo(() => {
    const lookup: { [key: string]: Concern } = {};
    if (concerns && concerns.length > 0) {
      concerns.forEach((c) => {
        const adjustedZoneIndex = zones.length - 1 - c.zone_index;
        lookup[`${adjustedZoneIndex}-${c.time_index}`] = c;
      });
    }
    return lookup;
  }, [concerns, zones.length]);

  const formatValue = (target: number, rowIndex: number, colIndex: number) => {
    const concernKey = `${rowIndex}-${colIndex}`;
    const hasConcern = concernKey in conLookup;

    if (target === 0) {
      return <span className={"text-gray-600"}>{target}</span>;
    }

    let ratio = 0;
    const sat = "80";
    const lum = "50";
    if (max != min) ratio = (target - min) / (max - min);
    const hue = (1 - ratio) * 120;
    const color = `hsl(${hue}, ${sat}%, ${lum}%)`;

    return (
      <span
        className={`${hasConcern ? "bg-red-900 px-2 py-1 rounded cursor-pointer" : ""}`}
        style={{ color }}
        onClick={() => {
          if (hasConcern) {
            setSelectedConcern(conLookup[concernKey]);
            setOpen(true);
          }
        }}
      >
        {target.toLocaleString()}
      </span>
    );
  };

  return (
    <>
      <div className="flex w-full overflow-hidden text-xs font-mono">
        <div className="flex w-32 overflow-hidden ">
          {/* Fixed Column Table */}
          <table className="w-full whitespace-nowrap mt-[1px]">
            <thead>
              <tr>
                <th className="sticky top-0 text-left p-2 dark:bg-slate-700">
                  Name
                </th>
              </tr>
            </thead>
            <tbody>
              {zones.map((zone, i) => (
                <tr key={i} className={"even:bg-slate-800"}>
                  <td className="py-1 px-2 h-8">{zone.name}</td>
                </tr>
              ))}
              <tr className="">
                <td className="py-1 px-2 h-8">Total</td>
              </tr>
              {/* Spacer */}
              <tr className={"h-12"}></tr>
            </tbody>
          </table>
        </div>
        <div className="flex-1 overflow-x-scroll">
          {/* Scrollable Table */}
          <table className="w-full whitespace-nowrap">
            <thead>
              <tr>
                {zones[0].values.map((_, i) => {
                  return (
                    <th className="table-header-cell table-cell-single" key={i}>
                      {times[i]}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {zones.map((zone, rowIndex) => (
                <tr key={rowIndex} className={"even:bg-slate-800"}>
                  {zone.values.map((value, colIndex) => (
                    <td
                      className="table-data-cell table-cell-single"
                      key={colIndex}
                    >
                      {formatValue(value, rowIndex, colIndex)}
                    </td>
                  ))}
                </tr>
              ))}
              {/* Totals Row */}
              <tr className="bg-slate-600">
                {zones[0].values.map((foo, colIndex) => (
                  <td className="table-data-cell" key={colIndex}>
                    {parseInt(
                      _.sumBy(zones, (zone) => zone.values[colIndex]),
                    ).toLocaleString("en-US")}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex w-16 overflow-hidden ">
          {/* Fixed Column Table */}
          <table className="w-full whitespace-nowrap mt-[1px]">
            <thead>
              <tr>
                <th className="sticky top-0 text-left p-2 dark:bg-slate-700">
                  Totals
                </th>
              </tr>
            </thead>
            <tbody>
              {zones.map((zone, i) => (
                <tr key={i} className={"even:bg-slate-800"}>
                  <td className="py-1 px-2 h-8 dark:text-slate-400">
                    {_.sum(zone.values).toLocaleString()}
                  </td>
                </tr>
              ))}
              <tr className="">
                <td className="py-1 px-2 h-8"></td>
              </tr>
              {/* Spacer */}
              <tr className={"h-12"}></tr>
            </tbody>
          </table>
        </div>
      </div>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Alert Details</DialogTitle>
          </DialogHeader>
          <ScrollArea className="h-[calc(100vh-250px)]">
            {selectedConcern && <ConcernDisplay concern={selectedConcern} />}
          </ScrollArea>
        </DialogContent>
      </Dialog>
    </>
  );
};
