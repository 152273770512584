import React, { useEffect } from "react";
import { useReportContext } from "@/stores/ReportContext";
import { Summary } from "@/queries/report";
import { useSessionData } from "@/stores/SessionDataContext";
import { Link } from "react-router-dom";

export const SummaryDisplay: React.FC<{
  totalCaption: string;
  breakdownTitle?: string;
  summary: Summary;
  disableConcourse?: boolean;
  concourseLinkPattern?: string;
  terminalLinkPattern?: string;
}> = ({
  breakdownTitle = "Terminal",
  totalCaption,
  summary,
  disableConcourse = false,
  concourseLinkPattern,
  terminalLinkPattern,
}) => {
  const { reportId } = useReportContext();
  const {
    airport_config: { has_concourse: hasConcourse },
  } = useSessionData();

  const formatWithSeverity = (val: number, severity: number) => {
    let classes = "";
    if (severity === 0) {
      classes += "text-green-600";
    }
    if (severity === 1) {
      classes += "text-amber-600";
    }
    if (severity === 2) {
      classes += "text-red-600";
    }
    const formatted = val.toLocaleString();

    return <span className={classes}>{formatted}</span>;
  };

  return (
    <div>
      <div
        className={
          "uppercase text-center text-amber-200 shadow-amber-300 text-lg"
        }
      >
        {totalCaption}
      </div>
      <div className={"text-center text-6xl mt-2"}>
        {formatWithSeverity(summary.total, summary.total_severity)}
      </div>
      <table className={"w-full mt-4 border border-slate-800"}>
        <tbody>
          <tr className={"border-b border-slate-600 "}>
            <td className={"text-sm text-center py-2 mb-2"}>
              {breakdownTitle}
            </td>
            <td></td>
            <td></td>
          </tr>
          {summary.breakdown.map((s, index) => {
            return (
              <tr key={index}>
                {!terminalLinkPattern && (
                  <td className={"text-center py-1"}>{s.name}</td>
                )}
                {terminalLinkPattern && (
                  <td className={"text-center py-1 underline text-indigo-400"}>
                    <Link
                      to={terminalLinkPattern.replace("{terminal}", s.name)}
                    >
                      {s.name}
                    </Link>
                  </td>
                )}
                <td className={"text-center"}>
                  {formatWithSeverity(s.amount, s.severity)}
                </td>
                <td className={"text-center"}>{s.percent + "%"}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {hasConcourse && !disableConcourse && (
        <table className={"w-full border border-slate-800 mt-6"}>
          <tbody>
            <tr className={"border-b border-slate-600 "}>
              <td className={"text-sm text-center py-2 mb-2"}>Concourse</td>
              <td></td>
              <td></td>
            </tr>
            {summary.breakdown_concourse.map((s, index) => {
              return (
                <tr key={index}>
                  {!concourseLinkPattern && (
                    <td className={"text-center py-1"}>{s.name}</td>
                  )}
                  {concourseLinkPattern && (
                    <td
                      className={"text-center py-1 underline text-indigo-400"}
                    >
                      <Link
                        to={concourseLinkPattern.replace("{concourse}", s.name)}
                      >
                        {s.name}
                      </Link>
                    </td>
                  )}

                  <td className={"text-center"}>
                    {formatWithSeverity(s.amount, s.severity)}
                  </td>
                  <td className={"text-center"}>{s.percent + "%"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};
