import { Route, Routes } from "react-router-dom";
import DataLayout from "@/pages/data/DataLayout";
import PaxOverviewPage from "@/pages/data/airport/PaxOverviewPage";
import React from "react";
import { DataPageProvider } from "@/pages/data/DataPageContext";
import AppLayout from "@/pages/layouts/AppLayout";
import TerminalOverviewPage from "@/pages/data/airport/TerminalOverviewPage";
import {
  TerminalCheckpointsPage,
  TerminalDoorPage,
  TerminalGatesPage,
  TerminalRestroomPage,
  TerminalTicketingPage,
} from "@/pages/data/terminal/TerminalInfoPages";
import {
  BaggageHallPage,
  CustomsPage,
} from "@/pages/data/airport/IntlFacilities";
import { PassengerFlowPage } from "@/pages/data/terminal/TerminalDashboardPages";
import {
  ConcourseGatesPage,
  ConcoursePassengerFlowPage,
  ConcourseRestroomPage,
} from "@/pages/data/concourse/ConcoursePages";
import {
  ConcoursePlaylistPage,
  TerminalPlaylistPage,
} from "@/pages/data/PlaysPage";

export default function DataRoutes() {
  return (
    <Routes>
      <Route
        path="/*"
        element={
          <DataPageProvider initialTitle="">
            <DataLayout />
          </DataPageProvider>
        }
      >
        <Route path={"airport/pax"} element={<PaxOverviewPage />} />
        <Route path={"airport/terminals"} element={<TerminalOverviewPage />} />
        <Route path={"airport/concerns"} element={<PaxOverviewPage />} />

        <Route path={"airport/intl"} element={<CustomsPage />} />
        {/*<Route*/}
        {/*  path={"airport/intl/baggage"}*/}
        {/*  element={<BaggageHallPage />}*/}
        {/*/>*/}

        <Route
          path={"terminal/:terminal/gates"}
          element={<TerminalGatesPage />}
        />
        <Route
          path={"terminal/:terminal/checkpoints"}
          element={<TerminalCheckpointsPage />}
        />
        <Route
          path={"terminal/:terminal/ticketing"}
          element={<TerminalTicketingPage />}
        />
        <Route
          path={"terminal/:terminal/restrooms"}
          element={<TerminalRestroomPage />}
        />

        <Route
          path={"terminal/:terminal/doors"}
          element={<TerminalDoorPage />}
        />

        <Route
          path={"terminal/:terminal/density"}
          element={<PaxOverviewPage />}
        />
        <Route
          path={"terminal/:terminal/flow"}
          element={<PassengerFlowPage />}
        />
        <Route
          path={"terminal/:terminal/pain-points"}
          element={<TerminalPlaylistPage />}
        />

        <Route
          path={"concourse/:concourse/gates"}
          element={<ConcourseGatesPage />}
        />
        <Route
          path={"concourse/:concourse/restrooms"}
          element={<ConcourseRestroomPage />}
        />

        <Route
          path={"concourse/:concourse/flow"}
          element={<ConcoursePassengerFlowPage />}
        />

        <Route
          path={"concourse/:concourse/pain-points"}
          element={<ConcoursePlaylistPage />}
        />
      </Route>
    </Routes>
  );
}
