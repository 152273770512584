import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "@/queries/client";
import { components } from "@/lib/openapi";

export type Play = components["schemas"]["PlaySchema"];
export type Action = components["schemas"]["ActionSchema"];
export type Activity = components["schemas"]["AuditSchema"];
export type FindPlays = components["schemas"]["FindPlaysSchema"];
export type PlayCreate = components["schemas"]["PlayCreateSchema"];
export type IncludesComment = components["schemas"]["IncludesCommentSchema"];
export function getPlays(reportId: number, config: FindPlays = {}) {
  return useQuery({
    queryKey: ["plays", reportId, config],
    queryFn: async ({ signal }) => {
      const { data, error } = await client
        .get()
        .POST("/api/report/{report_id}/plays", {
          signal,
          body: config,
          params: { path: { report_id: reportId } },
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}

export function getPlay(playId: number) {
  return useQuery({
    queryKey: ["single_play", playId],
    queryFn: async ({ signal }) => {
      const { data, error } = await client
        .get()
        .GET("/api/playlist/play/{play_id}", {
          signal,
          params: { path: { play_id: playId } },
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
  });
}

export function getActionsForPlay(playId: number) {
  return useQuery({
    queryKey: ["actions", playId],
    queryFn: async ({ signal }) => {
      const { data, error } = await client
        .get()
        .GET("/api/playlist/play/{play_id}/actions", {
          params: {
            path: {
              play_id: playId,
            },
          },
          signal,
        });
      if (data) return data.actions;
      console.warn(error);
      throw new Error();
    },
  });
}

export function getAuditForPlay(playId: number) {
  return useQuery({
    queryKey: ["audits", playId],
    queryFn: async ({ signal }) => {
      const { data, error } = await client
        .get()
        .GET("/api/playlist/play/{play_id}/audit", {
          params: {
            path: {
              play_id: playId,
            },
          },
          signal,
        });
      if (data) return data.audits;
      console.warn(error);
      throw new Error();
    },
  });
}

export function useCreateAdHocPlay(reportId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (config: PlayCreate) => {
      const { data, error } = await client.get().POST("/api/playlist/play", {
        params: {
          query: {
            report_id: reportId,
          },
        },
        body: config,
      });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["plays"] });
    },
  });
}

export function useMarkPlayScheduled(playId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (config: IncludesComment) => {
      const { data, error } = await client
        .get()
        .PATCH("/api/playlist/play/{play_id}/schedule", {
          params: {
            path: {
              play_id: playId,
            },
          },
          body: config,
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["plays"] });
      await queryClient.invalidateQueries({
        queryKey: ["single_play", playId],
      });
      await queryClient.invalidateQueries({ queryKey: ["audits"] });
    },
  });
}

export function useMarkPlayActioned(playId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (config: IncludesComment) => {
      const { data, error } = await client
        .get()
        .PATCH("/api/playlist/play/{play_id}/action", {
          params: {
            path: {
              play_id: playId,
            },
          },
          body: config,
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["plays"] });
      await queryClient.invalidateQueries({
        queryKey: ["single_play", playId],
      });
      await queryClient.invalidateQueries({ queryKey: ["audits"] });
    },
  });
}

interface CompleteProps {
  actionId: number;
  config: IncludesComment;
}

export function useCompleteAction(playId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ actionId, config }: CompleteProps) => {
      const { data, error } = await client
        .get()
        .PATCH("/api/playlist/play/{play_id}/action/{action_id}/complete", {
          params: {
            path: {
              play_id: playId,
              action_id: actionId,
            },
          },
          body: config,
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["plays"] });
      await queryClient.invalidateQueries({
        queryKey: ["single_play", playId],
      });
      await queryClient.invalidateQueries({ queryKey: ["audits", playId] });
      await queryClient.invalidateQueries({ queryKey: ["actions", playId] });
    },
  });
}

export function useMarkPlayCompleted(playId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (config: IncludesComment) => {
      const { data, error } = await client
        .get()
        .PATCH("/api/playlist/play/{play_id}/complete", {
          params: {
            path: {
              play_id: playId,
            },
          },
          body: config,
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["plays"] });
      await queryClient.invalidateQueries({
        queryKey: ["single_play", playId],
      });
      await queryClient.invalidateQueries({ queryKey: ["audits"] });
    },
  });
}

export function usePlayComment(playId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (config: IncludesComment) => {
      const { data, error } = await client
        .get()
        .PATCH("/api/playlist/play/{play_id}/comment", {
          params: {
            path: {
              play_id: playId,
            },
          },
          body: config,
        });
      if (data) return data;
      console.warn(error);
      throw new Error();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["plays"] });
      await queryClient.invalidateQueries({
        queryKey: ["single_play", playId],
      });
      await queryClient.invalidateQueries({ queryKey: ["audits"] });
    },
  });
}
